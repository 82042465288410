<template>
  <a-card :bodyStyle="{ padding: 50 }">
    <template #title>
      待送车 直接完成
    </template>
    <a-form ref="formRef" layout="horizontal" :model="form" :rules="rules">
      <a-form-item label="车牌/车架号">
        <a-input v-model:value="form.vinNo" placeholder="请输入"></a-input>
      </a-form-item>
      <a-form-item label="备注">
        <a-textarea v-model:value="reason" placeholder="备注" :rows="4" />
      </a-form-item>
    </a-form>
    <div style="text-align:right"><a-button type="primary" @click="submit">提交</a-button></div>
  </a-card>
</template>
<script >
import { reactive, toRefs } from 'vue'
import {employeeList } from '@/api/utils.js'
import { orderPickEdit } from '@/api/trans/ops/edit.js'
import { message } from 'ant-design-vue'
export default {
  props: { orderId: { type: String, default: '' }, subType: {} },
  emits: ['detail'],
  setup (props, { emit }) {
    const state = reactive({
      loading: false,
      timer: null,
      optionsList: [],
      searchSourceMan: '',
      reason: '',
      form: {
        orderId: props.orderId,
        vinNo: ''
      }
    })
   
 
    const submit = () => {
      if (state.form.vinNo != '') {
        if (state.reason != '') {
          orderPickEdit({
            params: state.form,
            type: {
              value: 0
            },
            reason: state.reason,
            subType: props.subType
          }).then(res => {
            if (res.code === 10000) {
              message.success(res.msg)
              state.reason = ''
              emit('success', false)
            }
          })
        } else message.error('请输入备注信息')
      } else message.error('请输入车架/车牌号')
    }

    return {
      ...toRefs(state),
      submit,
    }
  }
}
</script>
<style lang="less" scoped>
.ant-table td {
  white-space: nowrap;
}

.not-archive {
  color: #999;
}
</style>
