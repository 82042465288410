<template>
  <a-card>
    <div class="suspension-button">
      <a-space>
        <template v-if="form.orderStatus === 0">
          <a-button type="link" @click="edit">修改</a-button>
          <a-popconfirm title="确定推送该订单?" @confirm="send">
            <a-button type="link">推送</a-button>
          </a-popconfirm>
        </template>
        <a-button type="link" @click="modalVisibleHY = true" >合同</a-button>
        <a-button type="link" v-if="form.orderStatus !== 0 " @click="opsVisible = true" >订单数据修改</a-button>
      </a-space>
    </div>
    <table class="table-orderDetail">
      <thead>
        <tr>
          <th>
            <a-page-header title="订单详情" @back="backToInternal" />
          </th>
          <th>
            <ReloadOutlined @click="reload" />
          </th>
        </tr>
        <tr>
          <th class="left-td" colspan="2">
            <a-typography-paragraph type="danger" :copyable="{ text: form.order.orderId }">
              NO：{{ form.order.orderId }}
            </a-typography-paragraph>
          </th>
          <th colspan="6" class="left-td">
            
            <a-typography-text type="danger">订单来源： {{ form.order.orderSource }}</a-typography-text>
          </th>
          <th colspan="8">
            承运主体：{{ form.order.carrierBodyName }}
          </th>
          <th colspan="6">
            业务员：{{ form.order.sourceMan }}
          </th>
          <th class="right-td" colspan="8">
            录单员：{{ form.order.salesman }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <!--  委托方 -->
          <td rowspan="3" colspan="2" width="6%">
            <strong>委托方</strong>
          </td>
          <td class="label-td" colspan="2" width="5%">姓名</td>
          <td colspan="4" width="22%">
            {{ form.order.customerName }}
          </td>
          <!-- 交车人 -->
          <td rowspan="3" colspan="2" width="6%">
            <div style="font-weight:bold">起运地</div>
            <strong>交车人</strong>
          </td>
          <td class="label-td" colspan="2" width="6%">姓名</td>
          <td colspan="4" width="22%">
            {{ form.order.consignor.name }}
          </td>
          <!-- 取车人 -->
          <td rowspan="3" colspan="2" width="6%">
            <div style="font-weight:bold">目的地</div>
            <strong>取车人</strong>
          </td>
          <td class="label-td" colspan="2" width="6%">姓名</td>
          <td colspan="4" width="22%">
            {{ form.order.picker.name }}
          </td>
        </tr>
        <tr>
          <!--  委托方 -->
          <td class="label-td" colspan="2" width="5%">证件号</td>
          <td colspan="4" width="22%">
            {{ form.order.certificateNumber }}
          </td>
          <!-- 交车人 -->
          <td class="label-td" colspan="2" width="6%">证件号</td>
          <td colspan="4" width="22%">
            {{ form.order.consignor.certificateNumber }}
          </td>
          <!-- 取车人 -->
          <td class="label-td" colspan="2" width="6%">证件号</td>
          <td colspan="4" width="22%">
            {{ form.order.picker.certificateNumber }}
          </td>
        </tr>
        <tr>
          <!--  委托方 -->
          <td class="label-td" colspan="2" width="5%">电话</td>
          <td colspan="4" width="22%">{{ form.order.customerMobile }}</td>
          <!-- 交车人 -->
          <td class="label-td" colspan="2" width="6%">电话</td>
          <td colspan="4" width="22%">
            {{ form.order.consignor.mobile }}
          </td>
          <!-- 取车人 -->
          <td class="label-td" colspan="2" width="6%">电话</td>
          <td colspan="4" width="22%">
            {{ form.order.picker.mobile }}
          </td>
        </tr>
        <tr>
          <td class="label-td" colspan="2">对接人</td>
          <td colspan="22" class="left-td">
            <span v-show="form.order.businessContactPerson">对接人：{{ form.order.businessContactPerson
            }}</span>
            &emsp;
            <span v-show="form.order.businessContactMobile">对接人电话：{{ form.order.businessContactMobile
            }}</span>
          </td>
        </tr>
        <tr>
          <td class="label-td" colspan="2" width="6%">起运地</td>
          <td colspan="6" width="22%">
            <div style="width:100%;white-space:normal;word-wrap:break-word;word-break:break-all;">{{ form.order.startAddress.details }}</div>
          </td>
          <td class="label-td" colspan="2" width="6%">目的地</td>
          <td colspan="6" width="22%">
            <div style="width:100%;white-space:normal;word-wrap:break-word;word-break:break-all;"> {{ form.order.endAddress.details }}</div>
          </td>
          <td class="label-td" colspan="2" width="6%">所属机构</td>
          <td colspan="6">
            <a-row>
              <a-col :span="8">
                {{ form.order.officeOrgName }}
              </a-col>
            </a-row>
          </td>
        </tr>
        <tr>
          <td class="label-td" colspan="2">发票</td>
          <td colspan="6" class="left-td">
            {{ form.order.isInvoice }}
          </td>
          <td colspan="2" width="6%" class="label-td"><span style="font-size: 15px;">等通知放车</span></td>
          <td colspan="6">
            <div style="display:flex;justify-content: left;align-items:center">
              {{ form.order.isNoticeReleaseCar }}
              <span class="m-l2" v-if="form.order.noticeRemark">备注：{{form.order.noticeRemark}}</span>
            </div>
          </td>
          <td class="label-td" colspan="2" width="6%">结算方式</td>
          <td colspan="6">
            <a-row>
              <a-col :span="8">
                {{ form.order.orderSettlement.label }}
              </a-col>
              <a-col :span="16" v-if="form.order.orderSettlement.value === 3">
                预付 <a-typography-text type="danger"> ￥ {{ form.order.advanceCharge }}</a-typography-text>
                到收 <a-typography-text type="danger"> ￥ {{ form.order.paymentArrival }}</a-typography-text>
              </a-col>
              <a-col :span="16" v-if="form.order.orderSettlement.value === 5">
                记账期限
                <a-typography-text type="danger"> {{ form.order.deadline }} 天</a-typography-text>
              </a-col>
            </a-row>
          </td>
        </tr>
        <tr>
          <td class="label-td" colspan="2">返款</td>
          <td colspan="2" class="left-td">
            {{ form.order.isRefund.label }}
          </td>
          <template v-if="form.order.isRefund.value">
            <td class="label-td" colspan="2" width="6%">返款金额</td>
            <td colspan="2">
              <a-typography-text type="danger">￥ {{ form.order.refundFee }}</a-typography-text>
            </td>
            <td class="label-td" colspan="2" width="6%">收款人</td>
            <td colspan="6">
              {{ form.order.refunder }}
            </td>
            <td class="label-td" colspan="2" width="6%">返款电话</td>
            <td colspan="6">
              {{ form.order.refunderMobile }}
            </td>
          </template>
          <td v-else colspan="20" />
        </tr>
        <tr>
          <td class="label-td" colspan="2">总金额</td>
          <td colspan="22" class="left-td">
            <a-typography-text type="danger"> ￥ {{ form.totalFee }}</a-typography-text>
            大写： <a-typography-text type="danger"> {{ form.totalFeeChar }} </a-typography-text>
          </td>
        </tr>
        <tr>
          <td colspan="2" width="6%" class="label-td">备注</td>
          <td colspan="22" class="left-td">
            {{ form.order.remark }}
          </td>
        </tr>

        <template v-for="(item, index) in  form.orderVehicles" :key="index">
          <tr>
            <td class="label-td" colspan="24" />
          </tr>
          <tr class="vehicle-div">
            <td class="label-td" rowspan="5">
              <a-typography-title :level="4">车辆{{ index + 1 }}</a-typography-title>
            </td>
            <td class="label-td" colspan="2">车型</td>
            <td colspan="4" width="19%">
              {{ item.brand }} · {{ item.model }} {{ item.vehicleType?.label }}
            </td>
            <td class="label-td" colspan="2">车牌/车架号</td>
            <td colspan="4" width="14%">
              <a-typography-paragraph copyable>{{ item.vinNo }}</a-typography-paragraph>
            </td>
            <td class="label-td" colspan="2" width="6%">车辆状况</td>
            <td colspan="4">
              {{ item.status?.label }}
            </td>
            <td class="label-td" colspan="2" width="8%">运费（元）</td>
            <td colspan="4">
              <a-typography-text type="danger">￥ {{ item.freight }}</a-typography-text>
            </td>
          </tr>
          <tr class="vehicle-div">
            <td class="label-td" colspan="2">
              保险
            </td>
            <template v-if="item.insuranceCompany?.value === 1">
              <td colspan="21" class="left-td">
                {{ item.insuranceCompany.label }}
              </td>
            </template>
            <template v-else>
              <td colspan="4">
                {{ item.insuranceCompany?.label }}
              </td>
              <td class="label-td" colspan="4">保额（万元）</td>
              <td colspan="4" width="12%">
                {{ item.insuranceFee }}
              </td>
              <td class="label-td" colspan="4">保费（元）</td>
              <td colspan="5" width="14%">
                <a-typography-text type="danger">￥ {{ item.insurancePremium }}</a-typography-text>
              </td>
            </template>
          </tr>
          <tr class="vehicle-div">
            <td class="label-td" colspan="2" width="8%">取车</td>
            <td colspan="2" width="8%">
              <!--              {{item.isPlaceIn.label}}-->
              <span v-if="item.isPlaceIn?.value === 1">{{ item.pickType?.label || '是' }}</span>
            </td>
            <!-- 客户送车 -->
            <!-- <td v-show="item.isPlaceIn.value === 0" class="label-td" colspan="2" width="8%">是否到达门店</td>
            <td v-show="item.isPlaceIn.value === 0" class="label-td" colspan="2" width="8%">
              {{ item.isCusSend.value === 1 ? '是' : '否' }}
            </td> -->
            <td v-show="item.isPlaceIn.value === 0" class="label-td" colspan="2" width="8%"></td>
            <td v-show="item.isPlaceIn.value === 0" class="label-td" colspan="2" width="8%">
            </td>
            <td v-show="item.isPlaceIn.value === 0" class="label-td" colspan="2" width="8%"></td>
            <td v-show="item.isPlaceIn.value === 0" class="label-td" :colspan="item.isPlaceIn.value === 0 ? 12 : 5" width="22%" style="padding:5px 2px">

            </td>
            <template v-if="item.isPlaceIn && item.isPlaceIn.value">
              <td class="label-td" colspan="2" width="12%">取车费（元）</td>
              <td colspan="6" width="22%">
                <a-typography-text type="danger">￥ {{ item.placeInFee }}</a-typography-text>
              </td>
              <td class="label-td" colspan="2" width="8%">取车地址</td>
              <td colspan="9" width="36%">
                {{ item.placeInAddress }}
              </td>
            </template>
            <template v-else>
              <td colspan="18" />
            </template>
          </tr>
          <tr class="vehicle-div">
            <td class="label-td" colspan="2" width="8%">送车</td>
            <td colspan="2">
              <template v-if="item.isTakeOut && item.isTakeOut.value">
                <!--                {{item.isTakeOut.label}}-->
                <span v-if="item.isTakeOut?.value === 1">{{ item.takeType?.label || '是' }}</span>
              </template>
            </td>
            <!-- <td v-show="item.isTakeOut.value === 0" class="label-td" colspan="2" width="8%">是否到达门店</td>
            <td v-show="item.isTakeOut.value === 0" class="label-td" colspan="2" width="8%">
              {{ item.isCusPick.value === 1 ? '是' : '否' }}
            </td> -->
            <td v-show="item.isTakeOut.value === 0" class="label-td" colspan="2" width="8%"></td>
            <td v-show="item.isTakeOut.value === 0" class="label-td" colspan="2" width="8%">
            </td>
            <td v-show="item.isTakeOut.value === 0" class="label-td" colspan="2" width="8%"><span v-show="item.isTakeOut.value === 1">客户取车门店</span></td>
            <td v-show="item.isTakeOut.value === 0" class="label-td" :colspan="item.isTakeOut.value === 1 ? 6 : 13" width="8%" style="padding:5px 2px">

            </td>
            <template v-if="item.isTakeOut && item.isTakeOut.value">
              <td class="label-td" colspan="2" width="12%">送车费（元）</td>
              <td colspan="6" width="22%">
                <a-typography-text type="danger">￥ {{ item.takeOutFee }}</a-typography-text>
              </td>
              <td class="label-td" colspan="2" width="8%">送车地址</td>
              <td colspan="8" width="36%">
                {{ item.takeOutAddress }}
              </td>
            </template>
            <template v-else>
              <td v-show="item.isTakeOut.value === 1" colspan="18" />
            </template>
          </tr>
          <tr class="vehicle-div">
            <td class="label-td" colspan="2">备注</td>
            <td colspan="22" class="left-td">
              {{ item.remark }}
            </td>
          </tr>
        </template>
        <tr>
          <th class="right-td" colspan="24">
            录入时间：{{ form.order.orderTime }}
          </th>
        </tr>
      </tbody>
    </table>
  </a-card>
  <!-- 订单修改 -->
  <a-modal v-model:visible="opsVisible" title="订单修改" :width="600" :bodyStyle="{ height: '75vh', overflow: 'auto' }"
    cancelText=' ' okText=' ' destroy-on-close>
    <OrderOps @close="close" :orderId="form.order.orderId"  :isInvoice='form.order.isInvoice.value'  :label='form.order.carrierType?.label'></OrderOps>
  </a-modal>
  <!-- 新-华月车辆物流运输合同 -->
  <hy-contract v-model:visible="modalVisibleHY" v-if="modalVisibleHY" :totalFee="form.totalFee" :totalFeeChar="form.totalFeeChar" :form="orderMsg"></hy-contract>  
  <ly-modal v-model:visible="pushFlag" title="系统提示" delText="确定" @handleOk="pushFlag = false" :isTips="true"> 
      <p class="color-R">如需推送，请点击修改完善下面表单缺失的信息</p>
      <div v-for="item in tipsList" :key="item.id">
        {{ item }}
      </div>
    </ly-modal>
</template>
<script setup>
import { orderInfo, orderPush } from '@/api/order/order'
import { onMounted, reactive, toRefs, createVNode, ref } from 'vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import { message } from 'ant-design-vue'
import { amountUppercase } from '@/utils/util'
import OrderOps from '@/views/opsCenter/compents/OrderOps' 
import { ReloadOutlined, ExclamationCircleOutlined } from '@ant-design/icons-vue'
const store = useStore()
const router = useRouter()
const route = useRoute()
const editID = ref('')
const tipsList = ref([])
const orderMsg = ref({}) 
const pushFlag = ref(false)
const modalVisibleHY = ref(false)
const opsVisible = ref(false)
const form = reactive({
  order: {
    noticeRemark:'',
    orderId: '',
    carrierBodyName: '',
    sourceMan: '',
    salesman: '',
    customerName: '',
    certificateNumber: '',
    customerMobile: '',
    officeOrgName:'',
    consignor: {
      name: '',
      certificateNumber: '',
      address: ''
    },
    picker: {
      name: '',
      certificateNumber: '',
      address: ''
    },
    orderSource:'',
    businessContactPerson: '',
    businessContactMobile: '',
    startAddress: {
      details: ''
    },
    endAddress: {
      details: ''
    },
    orderSettlement: {
      label: '',
      value: '',
    },
    advanceCharge: '',
    paymentArrival: '',
    deadline: '',
    isInvoice: '',
    isRefund: '',
    refundFee: '',
    refunder: '',
    refunderMobile: '',
    remark: '',
    isNoticeReleaseCar: '',
    orderTime: '',
  },
  orderStatus: '',
  orderCustomer: {
    businessType: {}
  },
  orderVehicles: [],
  totalFee: 0.00,
  totalFeeChar: ''
})

const close = () => {
  opsVisible.value = false
  reqOrderInfo(editID.value)
}
const reqOrderInfo = (id) => {
  orderInfo(id).then(res => {
    if (res.code !== 10000) return
    orderMsg.value = res.data
    let order = res.data.order
    for (let key in order) {
      for (let val in form.order) {
        if (key == val) {
          if (order[key]?.label) {
            form.order[key] = order[key]?.label
          }
          else if (key == 'endAddress' || key == 'startAddress') {
            form.order[key] = order[key]
          }
          else {
            form.order[key] = order[key]
          }
          if (key === 'orderSettlement' || key === 'isRefund') {
            form.order[key] = order[key]
          }
        }
      }
    }
    form.totalFee = 0.00
    form.orderStatus = order.orderStatus.value
    res.data.orderVehicles.forEach(item => {
      form.totalFee = parseFloat(Number(form.totalFee) + Number(item.takeOutFee) + Number(item.placeInFee) + Number(item.insurancePremium) + Number(item.freight)).toFixed(2)
      item.totalFee = parseFloat(Number(item.takeOutFee) + Number(item.placeInFee) + Number(item.insurancePremium) + Number(item.freight)).toFixed(2)
    })
    form.totalFeeChar = amountUppercase(form.totalFee)
    form.orderVehicles = res.data.orderVehicles
  })
}
onMounted(() => {
  if (route.query.id) {
    editID.value = route.query.id
    reqOrderInfo(route.query.id)
  }
})
const reload = () => {
  reqOrderInfo(editID.value)
}
const edit = () => {
  router.push({ path: '/orderAdd', query: { id: editID.value } })
}
//推送
const send = () => {
  if(reuleOrder(form.order, form.orderVehicles)){
       tipsList.value =  reuleOrder(form.order, form.orderVehicles)
       pushFlag.value = true
       return
  }
  orderPush(editID.value).then( res => {
    if(res.code !== 10000) return
    reqOrderInfo(editID.value)
    message.success('推送成功')
  })
}
const backToInternal = () => {
  router.back()
}
const reuleOrder = (order, orderVehicles) => {
  let msg = {
    'consignor.name': '起运地交车人',
    'consignor.mobile': '交车人电话',
    'picker.name': '目的地取车人',
    'picker.mobile': '取车人电话',
  }
  let info = []
  for (let opt in msg)
    if (opt.split('.').length > 1) {
      if (!order[opt.split('.')[0]][opt.split('.')[1]]) {
        info.push(msg[opt])
      }
    }
  let Veh = {
    'vinNo': '车架号',
    'brand': '车型',
    'vehicleType': '车辆分类',
    'status': '车辆状况',
    'freight': '运费',
    'insuranceCompany': '保险',
    'insuranceFee': '保额',
    'insurancePremium': '保费'
  }
  let VehInfo = []
  let item = orderVehicles[0]
  for (let key in Veh) {
      if (!item[key] ) {
        VehInfo.push(Veh[key])
      }
  }
  let arr = info.concat(VehInfo)
 if( item.isPlaceIn?.value === 1){
  if(!item.pickType){
    arr.push('取车方式')
  }
  else if(!item.placeInFee){
    arr.push('取车费')
  }else if(!item.placeInAddress) {
    arr.push('取车地址')
  }
 }
 if( item.isTakeOut?.value === 1 ){
  if(!item.takeType){
    arr.push('送车方式')
  }
  else if(!item.takeOutFee){
    arr.push('送车费')
  }else if(!item.takeOutAddress) {
    arr.push('送车地址')
  }
 }
  if(arr.length>0){
    return arr
  }else{ 
    return false
  }
}






</script>
<style lang="less" scoped>
.ant-typography {
  margin: 0;
}

.suspension-button {
  position: absolute;
  right: 0px;
  top: 0px;
  padding: 10px 16px;
}

.table-orderDetail {
  width: 100%;
  font-size: 14px;

  td,
  th {
    text-align: center;
    padding: 5px 10px;
    white-space: nowrap;
  }

  td {
    position: relative;
    min-height: 20px;
    line-height: 20px;
    // border: 1px solid #c4dff5;
    border: 1px solid #000;
    max-width: 300px;
  }

  .left-td {
    text-align: left;
  }

  .right-td {
    text-align: right;
  }

  .label-td {
    font-size: 16px;
    font-weight: 600;
    color: #000;
  }
}

.vehicle-div {
  background: #eee;
}

.contract {
  &-title {
    text-align: center;
  }

  &-top {
    display: flex;
    justify-content: space-between;

    p {
      margin: 0;
    }
  }

  &-bottom {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &-mid {
    border: 1px solid #000;

    &-label {
      border-bottom: 1px solid #000;
      height: 45px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
    }

    &-value {
      text-indent: 1em;
      border-bottom: 1px solid #000;

      p {
        padding-top: 5px;
      }
    }

    &-text {
      border-bottom: 1px solid #000;

      p {
        text-indent: 2em;
      }
    }

    &-motion {
      color: red;

      p {
        margin: 0;
      }

      &-sign {
        width: 50%;
        display: flex;
        justify-content: space-around;
        flex-direction: row;

        b {
          flex: 1;
          color: #000;

          span {
            display: inline-block;
            font-weight: normal;
            margin-left: 50px;
          }
        }
      }
    }
  }

  &-head {
    &-label {
      font-size: 16px;
      font-weight: 600;
      text-align: right;
    }

    &-value {
      font-size: 16px;
      border-bottom: 1px solid #000;
      text-align: left;
      text-indent: 1em;
    }
  }

  &-content {
    font-size: 16px;
    text-indent: 2em;

    p {
      margin-bottom: 15px;
    }

    h1 {
      text-align: center;
    }

    &-sort {
      font-size: 18px;
      font-weight: 600;
    }

    &-label {
      font-size: 16px;
      font-weight: 600;
      text-indent: 2em;
    }

    // &-label-title{
    //   font-size: 18px;
    //   font-weight: bold;
    // }

    &-value {
      font-size: 16px;
      border-bottom: 1px solid #000;
      text-indent: 1em;
      text-align: left;
      height: 24px;
    }

    &-right {
      text-align: right;
      padding-right: 2em;
    }
  }

  &-footer {
    text-indent: 2em;
    margin-bottom: 8px;

    &-label {
      font-size: 16px;
      font-weight: 600;
    }

    &-value {
      font-size: 16px;
      text-indent: 1em;
    }
  }

  &-seal {
    width: 230px;
    margin-top: -120px;
    margin-left: 65%;
    filter: opacity(0.7);
  }
}

.bottom-img {
  position: absolute;
  width: 230px;
  top: -120px;
  left: 0%;
  filter: opacity(0.7);
}
</style>
