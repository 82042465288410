<template>
  <a-card :bodyStyle="{padding:50}">
    <template #title>
      打开送车信息修改
    </template>
    <a-form ref="formRef" layout="vertical" :model="form" :rules="rules">
      <a-row :gutter="16">
        <a-col :span="12">
          <a-form-item label="车架号/车牌号" name="vinNo">
            <a-input v-model:value="form.vinNo" />
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="16">
        <a-col :span="12">
          <a-form-item label="送车费用" name="fee">
            <a-input v-model:value="form.fee" />
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="16">
        <a-col :span="12">
          <a-form-item label="送车地址" name="address">
            <a-input v-model:value="form.address" />
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="16">
        <a-col :span="24">
          <a-form-item label="原因" name="reason">
            <a-textarea v-model:value="form.reason" :rows="6"
                        :maxlength="1000"
                        showCount />
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="16">
        <a-col :span="12">
          <a-button type="primary" @click="submitText">提交</a-button>
        </a-col>
      </a-row>
    </a-form>
  </a-card>
</template>
<script>
import { reactive, toRefs } from 'vue'
import { orderPickEdit } from '@/api/trans/ops/edit.js'
import { message } from 'ant-design-vue'
export default {
  props: { orderId: { type: String, default: '' }, subType: {} },
  emits: ['detail'],
  setup (props, { emit }) {
    const state = reactive({
      loading: false,
      formRef:null,
      form: {
        vinNo:'',
        fee:'',
        address:'',
        orderId: props.orderId,
        reason:''
      },
      rules:{
        vinNo: { required: true, message: '请输入车架号', trigger: 'blur' },
        fee:{ required: true, message: '请输入取车费用', trigger: 'blur' },
        address:{ required: true, message: '请输入取车地址', trigger: 'blur' },
        reason:{ required: true, message: '请输入原因', trigger: 'blur' }
      },
    })
    const editParam = {
      params: state.form,
      type: {
        label: '运单修改',
        value: 0
      },
      subType: props.subType
    }
    const submitText = () => {
      state.formRef.validate().then(() => {
        orderPickEdit({
        params: state.form,
        type: {
          value: 0
        },
        reason:  state.form.reason,
        subType: props.subType
      }).then(res => {
        if (res.code === 10000) {
          message.success(res.msg)
          state.form.fee = ''
          state.form.address = ''
          emit('success', false)
        }
      })
      })
     
    }
    return {
      ...toRefs(state),
      editParam,
      submitText
    }
  }
}
</script>
<style lang="less" scoped>
.ant-table td {
  white-space: nowrap;
}
.not-archive {
  color: #999;
}
</style>
