<template>
  <a-card :bodyStyle="{padding:50}">
    <template #title>
      产品服务信息修改
    </template>
    <a-form ref="formRef" layout="vertical" :model="form" :rules="rules">
      <a-row :gutter="16">
        <a-col :span="12">
          <a-form-item label="产品服务">
            <a-select v-model:value="form.productId">
              <a-select-option :value="null">无产品</a-select-option>
              <a-select-option v-for="item in products" :key="item.value">{{item.label}}</a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="16">
        <a-col :span="12">
          <a-form-item label="时效（天）">
            <a-input v-model:value="form.aging" />
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="16">
        <a-col :span="24">
          <a-form-item label="原因">
            <a-textarea v-model:value="reason" :rows="6"
                        :maxlength="1000"
                        showCount />
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="16">
        <a-col :span="12">
          <a-button type="primary" @click="submitText">提交</a-button>
        </a-col>
      </a-row>
    </a-form>
  </a-card>
</template>
<script>
import { reactive, toRefs } from 'vue'
import { orderPickEdit } from '@/api/trans/ops/edit.js'
// import { list as productList } from '@/api/trans/product'
import { message } from 'ant-design-vue'
import { onMounted } from '@vue/runtime-core'
export default {
  props: { orderId: { type: String, default: '' }, subType: {} },
  emits: ['detail'],
  setup (props, { emit }) {
    const state = reactive({
      loading: false,
      products: [],
      reason: '',
      form: {
        orderId: props.orderId
      }
    })
    const editParam = {
      params: state.form,
      type: {
        label: '运单修改',
        value: 0
      },
      subType: props.subType
    }
    const submitText = () => {
      orderPickEdit({
        params: state.form,
        type: {
          value: 0
        },
        reason: state.reason,
        subType: props.subType
      }).then(res => {
        if (res.code === 10000) {
          message.success(res.msg)
          state.form.productId = undefined
          state.form.aging = ''
          emit('success', false)
        }
      })
    }
    const loadData = () => {
      productList({}).then(res => {
        state.products = res.data
      })
    }
    onMounted(loadData)
    return {
      ...toRefs(state),
      editParam,
      submitText,
      loadData
    }
  }
}
</script>
<style lang="less" scoped>
.ant-table td {
  white-space: nowrap;
}
.not-archive {
  color: #999;
}
</style>
